<template>
  <b-card class="mp-0">

    <b-col cols="12" class="mt-3 pr-5">
            <b-button class="float-right" size="sm" variant="danger" >
              <feather-icon class="mr-50"/>
              <span>Decline</span>
            </b-button>
          </b-col>

    <b-col cols="12" class="mb-2">
      <b-button class="float-right" size="sm" variant="primary" >
        <feather-icon class="mr-50"/>
        <span>Approve</span>
      </b-button>
    </b-col>

    <b-tabs >
      <b-tab title="General" lazy>
        <div class="alert-body"><General /> </div>
      </b-tab>


      <b-tab title="Address" lazy>
        <div class="alert-body"><Address /> </div>
      </b-tab>


      <b-tab title="Document" lazy>
        <div class="alert-body"><Document /> </div>
      </b-tab>


    </b-tabs>



  </b-card>
</template>

<script>
import { BCard, BTabs , BTab, BButton, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import General from './view/general'
import Address from './view/address'
import Document from './view/document'

export default  {
  name: 'Home',
  components: {
    BCard,
    BTabs,
    BTab,
    BButton,
    General,
    Address,
    Document
  },
  directives: {
    Ripple,
  },
  data() {
    return {

    }
  },

  mounted() {
    console.log(this.$route.params.id);
  }
}
</script>
