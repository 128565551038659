<template>
  <b-row>
    <b-col
        md="6"
        xl="4"
        class="mb-1"
    >

      <!-- basic -->
      <b-form-group
          label="First Name"
          label-for="readOnlyInput"
      >
        <b-form-input
            value="Jenny"
            readonly
        />
      </b-form-group>
    </b-col>

    <b-col
        md="6"
        xl="4"
        class="mb-1"
    >
      <!-- input text with help -->
      <b-form-group
          label="Last Name"
          label-for="readOnlyInput"
      >
        <b-form-input
            value="Wilson"
            readonly
        />
      </b-form-group>
    </b-col>

    <b-col
        md="6"
        xl="4"
        class="mb-1"
    >
      <!-- disabled input -->
      <b-form-group
          label="Gender"
          label-for="readOnlyInput"
      >
        <b-form-input
            value="Female"
            readonly
        />
      </b-form-group>
    </b-col>

    <b-col
        md="6"
        xl="4"
    >
      <!-- with helper text -->
      <b-form-group
          label="Role"
          label-for="readOnlyInput"
      >
        <b-form-input
            value="Nurse"
            readonly
        />
      </b-form-group>
    </b-col>
    <b-col
        md="6"
        xl="4"
    >
      <!-- readonly input -->
      <b-form-group
          label="Telephone Number"
          label-for="readOnlyInput"
      >
        <b-form-input
            value="0712345678"
            readonly
        />
      </b-form-group>
    </b-col>

    <b-col
        md="6"
        xl="4"
    >
      <!-- static text -->
      <b-form-group
          label="Email"
          label-for="readOnlyInput"
      >
        <b-form-input
            value="jenny@gmail.com"
            readonly
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {BFormInput, BRow, BCol, BFormGroup} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
  },
}
</script>